/* eslint-disable func-call-spacing */
/* eslint eol-last: ["error", "never"] */
import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import { Role } from '@/constants/role'

Vue.use(Router)

const router = new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [{
            path: '/',
            redirect: {
                path: '/login',
            },
        },
        {
            path: '/',
            component: () =>
                import ('@/views/dashboard/Noauth'),
            children: [
                // Login
                {
                    name: 'Login',
                    path: 'login',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/Login'),
                },
                // Logout
                {
                    name: 'Logout',
                    path: 'logout',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/components/core/Logout'),
                },
                // RecoverPassword
                {
                    name: 'RecoverPassword',
                    path: 'recoverpassword',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/RecoverPassword'),
                },
                // Unauthorize
                {
                    name: 'Unauthorized',
                    path: 'unauthorized',
                    meta: {
                        requiresAuth: false,
                        authorize: [],
                    },
                    component: () =>
                        import ('@/views/dashboard/pages/Unauthorized'),
                },
            ],
        },
        {
            path: '/notificaciones',
            meta: {
                requiresAuth: true,
                authorize: [Role.Notario, Role.User, Role.Oficial, Role.Cliente, Role.Admin],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [{
                name: 'Notificaciones',
                path: '/',
                meta: {
                    requiresAuth: true,
                    authorize: [Role.Notario, Role.User, Role.Oficial, Role.Cliente, Role.Admin],
                },
                component: () =>
                    import ('@/views/dashboard/Notificaciones'),
            }],
        },
        {
            path: '/admin',
            meta: {
                requiresAuth: true,
                authorize: [Role.Admin],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard administrador',
                    path: 'dashboard',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/Dashboard'),
                },
                // Estado Expediente
                {
                    name: 'Estado expediente',
                    path: 'maestros/estadoexp',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/EstadoExp'),
                },
                // Paises
                {
                    name: 'Paises',
                    path: 'maestros/paises',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/Paises'),
                },
                // Provincias
                {
                    name: 'Provincias',
                    path: 'maestros/provincias',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/Provincias'),
                },
                // Localidad
                {
                    name: 'Localidad',
                    path: 'maestros/localidad',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/Localidad'),
                },
                // Estado Civil
                {
                    name: 'Estado civil',
                    path: 'maestros/estadociv',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/EstadoCivil'),
                },
                // REM
                {
                    name: 'Régimen económico matrimonial',
                    path: 'maestros/rem',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/Rem'),
                },
                // RF
                {
                    name: 'Régimen foral',
                    path: 'maestros/rf',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/Rf'),
                },
                // RSB
                {
                    name: 'Régimen separación de bienes',
                    path: 'maestros/rsb',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/Rsb'),
                },
                // Cargos Societarios
                {
                    name: 'Cargos societarios',
                    path: 'maestros/cargossocietarios',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/CargosSocietarios'),
                },
                // PerfilDuracionFirma
                {
                    name: 'Perfiles de duración de firmas',
                    path: 'maestros/perfilduracionfirma',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/PerfilDuracionFirma'),
                },
                // SalasFirma
                {
                    name: 'Salas de firma',
                    path: 'maestros/salasfirma',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/SalasFirma'),
                },
                // TiposDocumentos
                {
                    name: 'Tipos de documentos',
                    path: 'maestros/tipodoc',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/maestros/TipoDocumentos'),
                },
                // Clientes admin
                {
                    name: 'Clientes',
                    path: 'usuarios/clientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/component/Clientes'),
                },
                // Procedimientos admin
                {
                    name: 'Procedimientos',
                    path: 'procedimientos',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/procedimientos/Procedimientos'),
                },
                // Expedientes admin
                {
                    name: 'Expedientes',
                    path: 'expedientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/expedientes/Expedientes'),
                },
                {
                    name: 'Agendas',
                    path: 'agenda',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/Agenda'),
                },
                {
                    name: 'Empleados',
                    path: 'usuarios/empleados',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/usuarios/Empleados'),
                },
                {
                    name: 'Perfil Administrador',
                    path: 'perfil',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Admin],
                    },
                    component: () =>
                        import ('@/views/dashboard/admin/AdminProfile'),
                },
            ],
        },
        {
            path: '/cliente',
            meta: {
                requiresAuth: true,
                authorize: [Role.Cliente],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard cliente',
                    path: 'dashboard',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Cliente],
                    },
                    component: () =>
                        import ('@/views/dashboard/cliente/Dashboard'),
                },
                // Ficha
                {
                    name: 'Ficha',
                    path: 'ficha',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Cliente],
                    },
                    component: () =>
                        import ('@/views/dashboard/cliente/Clientes'),
                },
                // Perfil
                {
                    name: 'Perfil Cliente',
                    path: 'perfil',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Cliente],
                    },
                    component: () =>
                        import ('@/views/dashboard/cliente/ClienteProfile'),
                },
                // Expedientes cliente
                {
                    name: 'Mis Expedientes',
                    path: 'expedientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Cliente],
                    },
                    component: () =>
                        import ('@/views/dashboard/cliente/Expedientes'),
                },
                {
                    name: 'Mi Agenda',
                    path: 'agenda',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Cliente],
                    },
                    component: () =>
                        import ('@/views/dashboard/cliente/Agenda'),
                },
            ],
        },
        {
            path: '/oficial',
            meta: {
                requiresAuth: true,
                authorize: [Role.Oficial],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard oficial',
                    path: 'dashboard',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Oficial],
                    },
                    component: () =>
                        import ('@/views/dashboard/oficial/Dashboard'),
                },
                // Clientes
                {
                    name: 'Clientes notaría',
                    path: 'clientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Oficial],
                    },
                    component: () =>
                        import ('@/views/dashboard/component/Clientes'),
                },
                // Expedientes oficial
                {
                    name: 'Expedientes Oficial',
                    path: 'expedientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Oficial],
                    },
                    component: () =>
                        import ('@/views/dashboard/oficial/Expedientes'),
                },
                // Perfil oficial
                {
                    name: 'Perfil Oficial',
                    path: 'perfil',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Oficial],
                    },
                    component: () =>
                        import ('@/views/dashboard/oficial/OficialProfile'),
                },
                {
                    name: 'Agendas notarios',
                    path: 'agenda',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Oficial],
                    },
                    component: () =>
                        import ('@/views/dashboard/oficial/Agenda'),
                },
            ],
        },
        {
            path: '/user',
            meta: {
                requiresAuth: true,
                authorize: [Role.User],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'UserDashboard',
                    path: 'dashboard usuario',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.User],
                    },
                    component: () =>
                        import ('@/views/dashboard/user/Dashboard'),
                },
                // Perfil
                {
                    name: 'Perfil Usuario',
                    path: 'perfil',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.User],
                    },
                    component: () =>
                        import ('@/views/dashboard/user/UserProfile'),
                },
                // Clientes
                {
                    name: 'Gestión clientes',
                    path: 'clientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.User],
                    },
                    component: () =>
                        import ('@/views/dashboard/component/Clientes'),
                },
            ],
        },
        {
            path: '/notario',
            meta: {
                requiresAuth: true,
                authorize: [Role.Notario],
            },
            component: () =>
                import ('@/views/dashboard/Auth'),
            children: [
                // Dashboard
                {
                    name: 'Dashboard notario',
                    path: 'dashboard',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Notario],
                    },
                    component: () =>
                        import ('@/views/dashboard/notario/Dashboard'),
                },
                // Clientes
                {
                    name: 'Consulta de clientes',
                    path: 'clientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Notario],
                    },
                    component: () =>
                        import ('@/views/dashboard/component/Clientes'),
                },
                // Expedientes oficial
                {
                    name: 'Expedientes Abiertos',
                    path: 'expedientes',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Notario],
                    },
                    component: () =>
                        import ('@/views/dashboard/notario/Expedientes'),
                },
                // Perfil notario
                {
                    name: 'Perfil Notario',
                    path: 'perfil',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Notario],
                    },
                    component: () =>
                        import ('@/views/dashboard/notario/NotarioProfile'),
                },
                {
                    name: 'Mis agendas',
                    path: 'agenda',
                    meta: {
                        requiresAuth: true,
                        authorize: [Role.Notario],
                    },
                    component: () =>
                        import ('@/views/dashboard/notario/Agenda'),
                },
            ],
        },
    ],
})

router.beforeEach((to, from, next) => {
    const authorize = to.meta.authorize
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.authorized) {
            if (authorize.length && authorize.includes(localStorage.getItem('role'))) {
                next()
            } else {
                next('/unauthorized')
            }
        } else {
            next('/login')
        }
    } else {
        next()
    }
})

export default router